<template>
    <div id="home">
        <div id="container">
            <div id="logo">
                <a href="/">
                    <img alt="简洁导航" src="../assets/img/simple-so.svg">
                </a>
            </div>
            <div id="search-tab">
                <span v-for="(item, index) in  engines"
                      v-bind:key="index"
                      :class="{active: getSearchEngine() === index}"
                      @click="setSearchEngine(item)">
                    {{ item.name }}
                </span>
            </div>
            <form id="search-form"
                  ref="search_form"
                  :action=selectEngine.url
                  target="_blank">

                <input id="search-keyword"
                       ref="search_input"
                       v-model="keyword"
                       :name=selectEngine.kw
                       :placeholder=selectEngine.hint
                       autocomplete=off
                       autofocus
                       type=search
                       @blur="blur()"
                       @focus="focus()"
                       @input="getHotKeyword()"
                       @keydown.down="onMove(1)"
                       @keydown.prevent.up="onMove(-1)">

                <input id="search-form-submit"
                       :value="selectEngine.searchBtn"
                       type="submit">
            </form>

            <!--百度搜索推荐-->
            <div id="hot-search" :style="hotSearchStyle">
                <li v-for="(keyword, index) in keywords"
                    v-bind:key="index"
                    :class="{selected:keySelected === index}"
                    @click="goUrlByHotSearch(keyword)">
                    <span :class="'search_index' + index">{{ index + 1 }}</span>{{ keyword }}
                </li>
            </div>

            <!--配置的地址, 手机端显示-->
            <div id="link-url">
                <span v-for="(item, index) in linkUrl"
                      :key="index"
                      @click="goUrlByLinkUrl(item.url)">
                    {{ item.title }}
                </span>
            </div>
        </div>
        <div id="foot">
            ©2022-2024
            <a class="out_link" href="/" target="_blank"></a> All rights reserved.
            <a class="out_link beian" href="https://beian.miit.gov.cn/" target="_blank">ICP</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import storage from '@/utils/storage'
import fetchJsonp from 'fetch-jsonp'

export default {
    name: 'Home',
    data() {
        return {
            keySelectEngine: 'key-engines-select-index',
            selectEngine: '',
            engines: [
                {
                    url: "https://www.baidu.com/s",
                    kw: "wd",
                    hint: "百度一下，你就知道",
                    name: "百度",
                    searchBtn: "百度"
                },
                {
                    url: "https://www.so.com/s",
                    kw: "q",
                    hint: "360搜索，SO靠谱",
                    name: "360",
                    searchBtn: "搜索"
                },
                {
                    url: "https://www.google.com/search",
                    kw: "q",
                    hint: "Google 搜索",
                    name: "谷歌",
                    searchBtn: "Google"
                },
                {
                    url: "https://cn.bing.com/search",
                    kw: "q",
                    hint: "微软 Bing",
                    name: "必应",
                    searchBtn: "搜索"
                },
                {
                    url: "https://www.sogou.com/web",
                    kw: "query",
                    hint: "上网从搜狗开始",
                    name: "搜狗",
                    searchBtn: "搜索"
                },
                {
                    url: "https://scholar.google.com/scholar",
                    kw: "q",
                    hint: "谷歌学术",
                    name: "学术",
                    searchBtn: "搜索"
                },
                {
                    url: "",
                    kw: "",
                    hint: "请输入百度文库地址",
                    name: "百度文库",
                    searchBtn: "解析"
                }
            ],
            linkUrl: [
                {
                    title: 'CHATGPT',
                    url: '/chatmobile',
                },
                {
                    title: '免费影视',
                    url: 'https://video.bwaq.cn',
                },
                {
                    title: '免费小说',
                    url: 'https://video.bwaq.cn/novel',
                },
                {
                    title: '电商优惠',
                    url: 'https://payback.bwaq.cn',
                },
            ],
            keyword: '',
            keywords: [],
            keySelected: -1,
            hotSearchStyle: 'display: none',
        }
    },
    created() {
        this.setSearchEngine(storage.get(this.keySelectEngine) || this.engines[0])
    },
    methods: {
        goUrlByHotSearch(val) {
            this.keyword = val
            this.$refs.search_input.value = val
            this.$refs.search_form.submit()
        },
        goUrlByLinkUrl(path) {
            if (path.startsWith('http://') || path.startsWith('https://')) {
                window.open(path, '_blank')
            } else {
                this.$router.push({name: path}, () => {
                })
            }
        },
        setSearchEngine(item) {
            if (item.name === "百度文库") {
                this.keyword = ''
                this.keywords = []
            }
            storage.set(this.keySelectEngine, item)
            this.selectEngine = item
        },
        getSearchEngine() {
            return storage.get(this.keySelectEngine)
        },
        getHotKeyword() {
            if (this.keyword === null || this.keyword.length === 0
                || this.selectEngine.name === "百度文库") {
                this.keywords = []
            } else {
                clearTimeout(this.timer) // 清除定时器
                this.timer = setTimeout(() => {
                    // https://sp0.baidu.com/5a1Fazu8AA54nxGko9WTAnF6hhy/su?wd=golang&cb=回调方法
                    let api = 'https://sp0.baidu.com/5a1Fazu8AA54nxGko9WTAnF6hhy/su?wd=' + this.keyword
                    fetchJsonp(api, {
                        jsonpCallback: 'cb',
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        this.keywords = data.s // 用到this一定要注意this指向
                    }).catch((error) => {
                        console.log(error)
                    })
                }, 50)
            }
        },
        onMove(direct) {
            this.keySelected = (this.keySelected + direct + this.keywords.length) % this.keywords.length
            this.keyword = this.keywords[this.keySelected]
            this.$refs.search_input.value = this.keywords[this.keySelected]
        },
        blur() {
            //清除延迟执行
            clearTimeout(this.timer)

            //设置延迟执行
            this.timer = setTimeout(() => {
                this.hotSearchStyle = 'display: none'
                this.keySelected = -1
            }, 100)
        },
        focus() {
            this.hotSearchStyle = 'display: block'
            this.keySelected = -1
            this.getHotKeyword()
        },
    },
}

</script>
<style lang="less" scoped>
@widthScreen: 600px;
@widthScreenSmall: 100%;

#home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#foot {
    position: absolute;
    bottom: 10px;
    z-index: 1000;
    text-align: center;
    width: 100%;
    color: #444444;
    height: 20px;
    line-height: 20px;
    font-size: 14px;

    .out_link {
        position: relative;
    }

    .out_link::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #444444;
        transform-origin: bottom right;
        transform: scaleX(0);
        transition: transform 0.4s ease;
    }

    .out_link:hover::before {
        transform-origin: bottom left;
        transform: scaleX(1);
    }
}

#container {
    margin-top: 10%;
    width: @widthScreen;

    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #logo {
        margin-bottom: 30px;

        img {
            height: 70px;
        }
    }

    #search-tab {
        width: 100%;
        font-weight: 700;
        height: 33px;
        margin: 0 0 20px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        -webkit-tap-highlight-color: transparent;

        span {
            height: 32px;
            padding: 0 8px;
            display: inline-block;
            font-size: 16px;
            line-height: 1;
            opacity: .6;
            color: #444444;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in
        }

        span.active, span:hover {
            opacity: 1;
            border-color: #838383
        }
    }

    #search-form {
        width: 100%;

        display: flex;
        flex-direction: row;

        input[type=search] {
            flex: 1;
            height: 46px;
            overflow: hidden;
            float: left;
            background: #F5F5F5;
            border: 2px solid #F5F5F5;
            border-radius: 2px;
            outline: none;
            line-height: 26px;
            font-family: inherit;
            font-size: inherit;
            padding: 0 15px;
            color: #333333;
            -webkit-appearance: none;

            &:hover {
                border: 2px solid #999999;
            }

            &:focus {
                border: 2px solid #999999;
                -webkit-transition: all .2s ease-in;
                transition: all .2s ease-in
            }

            &::-webkit-search-cancel-button {
                width: 15px;
                height: 15px;
                color: #444444;
                cursor: pointer;
                -webkit-appearance: none;
                background: url("../assets/img/clear.png") no-repeat;
                background-size: contain;
            }
        }

        input[type=submit] {
            max-width: 130px;
            width: 26%;
            font-weight: 700;
            margin-left: 10px;
            height: 46px;
            font-family: inherit;
            font-size: inherit;
            -webkit-appearance: none;

            text-transform: uppercase;
            cursor: pointer;
            color: #ffffff;
            background: #888888;
            border: 2px solid #888888;
            border-radius: 2px;
            -webkit-transition: .2s;
            transition: .2s;

            &:hover {
                border: 2px solid #999999;
                background-color: #999999
            }
        }

    }

    #hot-search {
        top: 0;
        width: 100%;
        background: #f5f5f5;
        overflow: hidden;
        position: relative;

        li {
            margin-top: 5px;
            font-size: 15px;
            list-style: none;
            cursor: pointer;
            height: 25px;
            line-height: 25px;
            color: #838383;

            span {
                -webkit-tap-highlight-color: #00000000;
                list-style: none;
                cursor: pointer;
                display: inline-block;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                background: #e5e5e5;
                margin-right: 10px;
                color: #535353;
                border: 1px solid #f5f5f5;
            }

            .search_index0 {
                color: #ffffff;
                background: #F54545;
            }

            .search_index1 {
                color: #ffffff;
                background: #FF8547;
            }

            .search_index2 {
                color: #ffffff;
                background: #FFAC38;
            }
        }

        li:hover {
            background: white;
            color: #444444;
        }

        li.selected {
            background: white;
            color: #444444;
        }
    }

    #link-url {
        width: 100%;
        padding: 20px 0 70px;
        display: none;

        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;

        span {
            color: #757889;
            font-size: 14px;
        }
    }
}

@media (max-width: 600px) {
    #logo {
        margin-bottom: 20px !important;

        img {
            height: 45px !important;
        }
    }

    #foot {
        display: none !important;
    }

    #container {
        width: 90% !important;
        margin-top: 30% !important;
    }

    #hot-search {
        display: none !important;
    }

    #link-url {
        display: flex !important;
    }

    #search-tab span {
        font-size: 14px !important;
    }
}
</style>
